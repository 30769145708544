@import "../../variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$maxWidth: 1280;

.dpd-header {
  position: fixed;
  left: 10px;
  left: calc((100vw - #{$maxWidth}px) / 2);

  transform: translateY(-60%) translateZ(0);
  z-index: 10;

  @media (max-width: #{$maxWidth - 10}px) {
    left: 10px;
  }

  & img {
    min-width: 80px;
    width: 18vw;
    max-width: 180px;
    
  }
}