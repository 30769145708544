@import "../../variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.dpd-circle-button {
  text-decoration: none;
  display: inline-block;
  border-radius: 50%;
  padding: 1px 1px;
  text-align: center;
  border: none;
  transition: all 300ms linear;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;

  &:active {
    color: white;
  }

  &:focus {
    outline: none;
  }

  background-color: $DarkBlue;
  &:hover {
    color: white;
    background-color: $LightBlue;
  }

  @include media-breakpoint-down(sm) {
    min-width: 34px;
    min-height: 34px;
    line-height: 32px;
  }
}
