@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.scene-2-navigation {
  display: flex;
  justify-content: center;

  .wrapper {
    display: block;
    width: 100%;
    padding: 10px;
  }

  h2 {
    display: block;
    text-align: center;

    color: $ButtonBlue;
    font-size:  73px;
    font-weight: 700;
    letter-spacing: normal;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 38px;
    }
  }

  .grid-content {
    position: relative;
    width: 100%;
    max-width: 900px;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 20px auto;
    position: relative;
    justify-content: center;

    .nav-group {
      display: flex;
      width: 100%;
      position: relative;
      justify-content: space-around;
      margin-bottom: 4%;
      margin-top: 1%;
    }

    .cell {
      width: 16%;
      z-index: 5;
      @include media-breakpoint-down(xs) {
        width: 23%;
      }

      figure {
        text-align: center;
        transition: all ease-in 300ms;
        transform: scale(1) translateZ(0);

        img {
          max-width: 100%;
        }
        figcaption {
          text-transform: uppercase;
          line-height: 150%;
          font-weight: 600;
          padding-top: 5px;
          color: $TextColor;
          text-align: center;
          white-space: nowrap;

          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);

          width: 150%;
          font-size: 2.5vw;

          @media (min-width: 399px) {
            font-size: 2vw;
          }

          @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 25px;
          }
        }
        &:hover {
          transform: scale(1.1) translateZ(0);
        }
      }
    }
  }
}
