// Colours
$TextColor: #333333;
$LightBlue: #0485B9;
$DarkBlue: #17338a;

$AHref: #007bff;

$ButtonBlue: #1063a3;
$ButtonBlack: #333333;

$SecondaryBackground: #0b3c5f;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 780px,
  lg: 992px,
  xl: 1200px
);
