@import "../../variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.dpd-video {
  background-color: transparent;
  width: 100%;
  min-width: 250px;
  max-width: 70vw;
  margin-top: 10px;
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 100%;
  }
}

.dpd-image {
  padding: 20px;
  max-width: 65%;
  max-height: 65%;
  margin: 0 auto;
  object-fit: contain;

  @include media-breakpoint-up(md) {
    max-width: 90%;
    max-height: 90%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 100%;
    max-height: 100%;
  }
}

.dpd-image-full-width {
  object-fit: contain;
  padding: 0px;
  max-width: 95%;
  max-height: 95%;
  margin: 0 auto;
}

.dpd-text-header {
  font-size: 60px;
  color: $DarkBlue;
  line-height: 55px;
  letter-spacing: -4px;
  font-weight: 700;

  &.long {
    @include media-breakpoint-up(md) {
      font-size: 52px;
    }
  }

  &.small {
    line-height: 30px;
    letter-spacing: 0px;
    font-weight: 600;
    font-size: 20px;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px ;
    }
  }
  .header-blue-text {
    color: $LightBlue;
  }

  @include media-breakpoint-down(sm) {
    font-size: 40px;
    line-height: 45px;
  }
}

.dpd-text {
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 300;
  color: $TextColor;
  line-height: 23px;
  font-size: 15px;
  text-align: left;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
    line-height: 20px;
  }
}

.blue-link {
  color: $AHref;
  &:hover {
    text-decoration: none;
  }
  @include media-breakpoint-down(sm) {
    font-weight: 400;
  }
}

.dpd-tv-video {
  width: 85%;
}

.dpd-arrow {
  font-family: 'Poppins', 'Roboto', sans-serif;
  background-color: $DarkBlue;
  line-height: 26px;
  border-radius: 25px;
  padding: 0px 5px;
  width: 26px;
  max-width: 26px;
  max-height: 26px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  color: $white;

  a {
    color: $white;
  }
  &:hover {
    color: $white;
    background-color: $AHref;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.navigation-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .navigation-button {
    text-transform: uppercase;
    color: $white;
    padding: 0px 25px;
    border-radius: 25px;
    text-align: center;
    font-weight: 700;
    font-size: 17px;
    width: 125px;
    height: auto;
    line-height: 40px;
    border: none;
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      font-size: 15px;
    }
    .button-text {
      &:before, &:after {
        font-family: 'FontAwesome';
        font-size: 16px;
        width: 17px;
        height: auto;
        font-weight: 400;
        line-height: 17px;
      }
    }
    &.button-back {
      background-color: $ButtonBlack;
      .button-text {
        &:before {
          content: '\f0a8';
          margin-right: 3px;
        }
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
    &.button-next {
      background-color: $ButtonBlue;
      .button-text {
        &:after {
          content: '\f0a9';
          margin-left: 3px;
        }
      }
      &.button-last {
        .button-text {
          &:after {
            content: '\f0ab';
          }
        }
      }
    }
    &:hover {
      text-decoration: none;
      background-color: $LightBlue;
    }
    &:focus {
      outline: none;
    }
  }
}

.dpd-content-list {
  font-family: 'Poppins', 'Roboto', sans-serif;
  padding-left: 20px;
  list-style-type: circle;
  font-size: 15px;
  line-height: 23px;
  text-align: left;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
    line-height: 20px;
    font-weight: 300;
  }
}

.dpd-iframe {
  border-top: 30px solid $ButtonBlue;
  border-bottom: 30px solid $ButtonBlue;
}

.dpd-read-more {
  text-transform: uppercase;
  color: $white;
  padding: 0px 25px;
  border-radius: 25px;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  height: auto;
  line-height: 40px;
  border: none;
  background-color: $DarkBlue;

  &:hover {
    background-color: $LightBlue;
  }
  &:focus {
    outline: none;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
    font-size: 15px;
  }
}
