
@mixin appear-animation($opacity: 0, $animation-fill-mode: forwards) {
  opacity: $opacity;
  animation-fill-mode: $animation-fill-mode;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 400ms;
  
  // animation-delay: 0s;
  animation-direction: normal;
}

$delays: 0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1400, 1600, 1800, 2000;

@each $delay in $delays {
  .dpd-anim-delay-#{$delay} {
    animation-delay: #{$delay}ms;
  }
}


@keyframes dpd-fade-left-frame {
  0% {
    opacity: 0;
    transform: translateX(50px) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) translateZ(0);
  }
}
.dpd-fade-left { @include appear-animation; }

@keyframes dpd-fade-right-frame {
  0% {
    opacity: 0;
    transform: translateX(-50px) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) translateZ(0);
  }
}
.dpd-fade-right { @include appear-animation; }
@keyframes dpd-fade-up-frame {
  0% {
    opacity: 0;
    transform: translateY(50px) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) translateZ(0);
  }
}
.dpd-fade-up { @include appear-animation; }
@keyframes dpd-fade-down-frame {
  0% {
    opacity: 0;
    transform: translateY(-50px) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) translateZ(0);
  }
}
.dpd-fade-down { @include appear-animation; }
@keyframes dpd-scale-up-frame {
  0% {
    transform: scale(0.9) translateZ(0);
    opacity: 0;
  }
  100% {
    transform: scale(1.0) translateZ(0);
    opacity: 1;
  }
}
.dpd-scale-up { @include appear-animation; }
@keyframes dpd-swing-advice-frame {
  0% {
    transform-origin: left;
    transform: translateX(0px) translateY(20px) rotate(5deg) scale(1.2) translateZ(0);
    opacity: 0.7;
  }
  100% {
    transform-origin: left;
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1.0) translateZ(0);
    opacity: 1;
  }
}
.dpd-swing-advice { @include appear-animation; }

.dpd-scale-up-iframe { @include appear-animation; }

// trigger animation when content is visible on screen
.dpd-scene--viewed {
  .dpd-fade-left { animation-name: dpd-fade-left-frame; }
  .dpd-fade-right { animation-name: dpd-fade-right-frame; }
  .dpd-fade-up { animation-name: dpd-fade-up-frame; }
  .dpd-fade-down { animation-name: dpd-fade-down-frame; }
  
  .dpd-scale-up { animation-name: dpd-scale-up-frame; }
  .dpd-swing-advice { animation-name: dpd-swing-advice-frame; }
  
  .dpd-scale-up-iframe { 
    animation-name: dpd-scale-up-frame;

    &.animated {
      // fix desktop safari not allow open iframe fullscreen mode
      animation-fill-mode: none;
      animation-delay: 0s;
      opacity: 1;
    }
  }
}
