@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.scene-1-title {
  //  TODO improve styles for mobile and tablet view

  h1 {
    font-family: Poppins;
    font-size: 92px;
    font-weight: 800;
    letter-spacing: -4px;
    @include media-breakpoint-down(sm) {
      font-size: 41px;
      line-height: 27px;
      letter-spacing: -1px;
    }
  }

  img {
    max-width: 100%;
    max-height: 60vh;
    min-height: 150px;
  }

  video {
    max-width: 100%;
    width: 80%;
    margin: -20px 0px;
  }
}
