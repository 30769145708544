@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dpd-carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh;
  align-items: center;
  @include media-breakpoint-between(md, md) {
    padding: 20px 0;
  }
  .dpd-carousel__content {
    position: relative;
    flex: 1;
    // flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    touch-action: pan-y;

    .dpd-carousel__content-item {
      display: none;

      @include media-breakpoint-between(md, md) {
        padding-bottom: 50px;
      }
      &--active {
        display: block;
      }
    }
  }

  .dpd-carousel__bullets {
    position: relative;
    flex-shrink: 1;
    display: none;
    justify-content: center;
    margin: 10px 20px 10px;
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .dpd-carousel__bullet {
      display: none;
      margin: 3px;
      width: 26px;
      height: 26px;
      border-radius: 100%;
      background-color: $DarkBlue;
      border: 3px solid $white;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        display: block;
      }

      &:hover {
        background: $LightBlue;
      }

      &--active {
        background: $LightBlue;
      }
    }
  }
}

.dpd-carousel__list {
  padding: 0 0 0 20px;
  .dpd-carousel__list-item {
    color: $DarkBlue;
    font-size: 15px;
    font-weight: 300;
    line-height: 16px;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all 0.2s;
    margin: 5px 0;
    min-height: 18px;
    text-transform: capitalize;

    // li width will be based on it content 
    clear: both;
    float: left;

    &:after {
      margin-left: 7px;
      content: '\f0a9';
      font-family: 'FontAwesome';
    }

    &:hover {
      color: $LightBlue;
      font-size: 16px;
      cursor: pointer;
      transform: matrix3d(1.03, 0, 0, 0, 0, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    @include media-breakpoint-down(sm) {
      font-size: 13px;
      line-height: 20px;
    }
  }
}
