@import "../../variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.diversity-popup {

  .modal-content {
    background-color: $SecondaryBackground;
    border-radius: 10px;
    border: none;
    color: white;

    font-size: 16px;
    font-family: sans-serif;

    p {
      margin-bottom: 25px;
    }
  }

  .modal-header {
    padding: 15px;


    .modal-title {
      font-size: 20px;
    }

    .close {
      font-size: 26px;
      text-shadow: none;
      color: white;
      opacity: 0.6;
    }
  }

  .modal-body {
    padding: 15px;
  }

  .diversity-logo {
    max-width: 100%;
    max-height: 100%;
  }
}