@import "../../variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$button-color: $SecondaryBackground;
$button-color-hover: #3482e2;
$button-color-active: #3482e2;

@keyframes button-appear {
  0% {
    transform: scale(0) translateZ(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }
}

.dpd-navigation {
  position: fixed;
  z-index: 15;
  bottom: 25px;
  right: 0;
  padding-right: 25px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;

  &__button {
    position: relative;
    outline: none;
    outline-offset: 0px;
    border: none;
    background-color: $button-color;
    min-height: 60px;
    min-width: 60px;
    margin-left: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px 15px;
    font-size: 17px;
    font-weight: bold;
    transition: transform linear 300ms;
    box-shadow: 10px 10px 20px rgba(0,0,0,.5);
    
    opacity: 0;
    animation: button-appear 700ms ease-in 0s 1;
    animation-fill-mode: forwards;

    &:hover {
      background-color: $button-color-hover;
    }

    &:focus {
      outline: none;
      outline-offset: 0px;
    }
  }

  &__button-email > img {
    position: absolute;
    width: 30px;
    transform: translate(-50%, -50%)  translateZ(0);
    top: 50%;
    left: 50%;
  }
}

.dpd-navigation-h-icon {
  position: absolute;
  transform: translate(-50%, -50%)  translateZ(0);
  top: 50%;
  left: 50%;
  z-index: 101;

  & > .line {
    width: 25px;
    height: 2px;
    border-radius: 40px;
    background-color: white;
    display: block;
    margin: 6px 0px;
    transition: transform 200ms linear 0ms;
  }
}

.dpd-navigation-h-icon--active {
  background-color: $button-color-active;

  & .dpd-navigation-h-icon .line-1 {
    transform-style: flat;
    transform: translateY(8px) rotate(45deg) translateZ(0);
  }

  & .dpd-navigation-h-icon .line-2 {
    transform-origin: left;
    transform: scale(0) translateZ(0);
    opacity: 0;
  }

  & .dpd-navigation-h-icon .line-3 {
    transform: translateY(-8px) rotate(-45deg) translateZ(0);
  }
}


.dpd-navigation-h-content {
  z-index: 100;
  position: fixed;
  top: 10px;
  bottom: 90px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  transform-origin: bottom;
  transform: scale(1, 0) translateZ(0);
  opacity: 0;

  & > ul {
    list-style-type: none;
    padding: 25px 25px 25px 0;
    padding-bottom: 15px;
    
    overflow: auto;
    // scroll fix
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    
    margin: 0;
  }

  & li {
    transition: all 300ms linear 0ms;
    margin: 0px 0px;
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
  }

  & a {
    position: relative;
    display: flex;
    width: 50px;
    height: 50px;
    background-color: $button-color-active;
    border-radius: 50px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, .3);
    cursor: pointer;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(xl) {
      width: 60px;
      height: 60px;
    }

    &:hover {
      background-color: $button-color-hover;
    }
  }

  & i {
    font-family: dashicons;
    font-size: 25px;
    font-style: normal;
    color: white;
  }
  &--animated {
    transition: all 300ms linear 0ms;
  }
  
  &--active {
    transform: scale(1) translateZ(0);
    opacity: 1;

    & li {
      margin: 4px 0px;
      
      @include media-breakpoint-up(xl) {
        margin: 7px 0px;
      }
    }
  }
}

.dpd-navigation-i-home:before {
  display: inline-block;
  content: '\f102';
  padding-bottom: 5px; 
}

.dpd-navigation-img {
  max-width: 30px;
  max-height: 30px;
  
  @include media-breakpoint-up (xl) {
    max-width: 36px;
    max-height: 36px;
  }

  &.big {
    max-width: 36px;
    max-height: 36px;
    
    @include media-breakpoint-up (xl) {
      max-width: 50px;
      max-height: 50px;
    } 
  }

  &.small {
    max-width: 24px;
    max-height: 24px;
    
    @include media-breakpoint-up (xl) {
      max-width: 28px;
      max-height: 28px;
    } 
  }
}
