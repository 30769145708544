
.tooltip {

  &.show {
    opacity: 1;
  }

  & > .tooltip-inner {
    max-width: 130px;
    color: #fff;
    background-color: #3482e2;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, .3);
    padding: 8px;
    min-height: 45px;
    min-width: 130px;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms linear 0ms;
    display: flex;
    text-align: center;
    font-size: 14px;
  }

  & > .arrow {
    &:before {
      border-left-color: #3482e2;
    }
  }
}