@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.graduates-popup {
  .modal-content {
    background-color: $SecondaryBackground;
    border-radius: 10px;
    border: none;
    color: white;

    font-size: 16px;
    font-family: sans-serif;

    p {
      margin-bottom: 25px;
    }
  }

  .modal-header {
    padding: 15px;

    .modal-title {
      font-size: 20px;
    }

    .close {
      font-size: 26px;
      text-shadow: none;
      color: white;
      opacity: 0.6;
    }
  }

  .modal-body {
    padding: 15px;
  }

  .avatar {
    margin: 0px 0px 10px 20px;
    width: 24%;
    border: 5px solid #fff;
    float: right;
  }
}
