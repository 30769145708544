$fa-font-path: '/assets/fonts';
@import '~font-awesome/scss/font-awesome';

@import './variables.scss';
@import '~bootstrap/scss/bootstrap';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

// TODO only using for home icon change it on image
@font-face {
  font-family: 'dashicons';
  src: url('/assets/fonts/dashicons.eot');
  src: url('/assets/fonts/dashicons.ttf');
  src: url('/assets/fonts/dashicons.woff2') format('woff2');
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.light-blue { color: $LightBlue; }
.dark-blue { color: $DarkBlue; }

.container-xl {
  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1260px;
  }

  @media (min-width: 1300px) {
    max-width: 1260px;
  }
}

.text-align-center {
  text-align: center;
}

.text-align-left-md {
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}
.dpd-footer {
  padding: 40px 0 120px;
  border-top: 1px solid #e7e7e7;
  font-size: 12px;
  font-weight: bold;
  font-family: 'roboto', helvetica, sans-serif;
  color: #212529;

  @include media-breakpoint-up(md) {
    padding: 40px 0px;
  }

  a {
    color: #000000 !important;
    font-weight: bold;
    text-decoration: underline;
  }
}