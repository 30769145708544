@import "../../variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

html{
  font-size: 12px;
  line-height: 12px;
}

.dpd-scene {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  padding: 5px 10px;

  @include media-breakpoint-up(md) {
    padding: 5px 15px;
  }

  .dpd-button {
    line-height: 40px;
    white-space: nowrap;
  }
}

.dpd-scene-side-text {
  white-space: nowrap;
  width: 101vh;
  position: absolute;
  left: 0;
  z-index: 100;
  transform: rotate(-90deg) translateY(50%) translateX(-50%) translateZ(0);
  transform-origin: left;
  text-align: center;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
  display: none;
  background: linear-gradient(rgb(25, 99, 160) 0%, rgb(25, 99, 160) 70%, rgb(5, 70, 135) 100%);
  border-color: rgb(33, 37, 41);
  padding-bottom: 10px;

  &.first-side-text {
    background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.04) 22%, rgba(255, 255, 255, 0) 23%),
                linear-gradient(#1963a0 0%, #1963a0 70%, #054687 100%);
  }

  &.last-side-text {
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.04) 22%, rgba(255, 255, 255, 0) 23%),
                linear-gradient(#1963a0 0%, #1963a0 70%, #054687 100%);
  }

  @media (min-width: 1440px) {
    display: block;
  }
}

@mixin scrollWrap {
  border-bottom: 1px solid black;
  padding: 5px 0 10px;
  margin: 5px 0 10px;
}

.dpd-scroll-wrap-md {
  @include media-breakpoint-down(md) {
    @include scrollWrap;
  }
}

.dpd-scroll-wrap-sm {
  @include media-breakpoint-down(sm) {
    @include scrollWrap;
  }
}

@mixin scroll($scroll) {
  max-height: #{$scroll}px;
  overflow: auto;
}

$scrolls-md: 150, 250, 275;

@each $scroll in $scrolls-md {
  .dpd-scroll-md-#{$scroll} {
    @include media-breakpoint-down(md) {
      @include scroll($scroll)
    }
  }
}

$scrolls-sm: 150, 175, 250, 275;

@each $scroll in $scrolls-sm {
  .dpd-scroll-sm-#{$scroll} {
    @include media-breakpoint-down(sm) {
      @include scroll($scroll)
    }
  }
}


