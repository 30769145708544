@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$background-color: $SecondaryBackground;

.server-error {
  .invalid-feedback {
    font-size: 16px;
  }
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: white !important;
  box-shadow: none;
}

.dpd-form-modal {
  font-family: 'Roboto', sans-serif;

  .modal-content {
    background-color: $background-color;
    color: white;
    border-radius: 10px;
    border: none;

    .modal-body {
      padding: 15px;

      &.submitted {
        & > * {
          display: none;
        }
        & > .submitted-successfully {
          display: block;
        }
      }
    }

    .submitted-successfully {
      background-color: rgb(224, 255, 199);
      color: black;
      font-size: 16px;
      padding: 15px;
      display: none;
      margin: 15px 0;

      p {
        font-size: 16px;
      }
    }

    p {
      font-size: 14px;
    }

    .form-control {
      font-size: 16px;

      &:valid,
      &:invalid {
        background: white;
      }
    }

    .responsive {
      max-width: 100%;

      @include media-breakpoint-up(sm) {
        max-width: 65%;
      }
    }

    a {
      color: white;
      text-decoration: underline;

      &:hover {
        color: white;
      }
    }

    .check-input {
      display: inline;

      & + label {
        display: inline;
      }
    }

    .required {
      font-size: 14px;
      vertical-align: top;
      margin: 5px;
      color: #dc3545;
    }

    .invalid-feedback {
      text-shadow: none;
      color: #dc3545;
      
    }

    button[type='submit'] {
      margin: 10px 0px;
      font-size: 16px;
      padding: 10px 20px;
      color: black;
      background-color: white;
    }

    .form-group {
      font-size: 16px;

      .label {
        font-weight: bold;
      }
    }
  }

  .modal-header {
    padding: 15px;

    .close {
      font-size: 25px;
      color: white;
      font-family: Roboto;
      opacity: .5;
    }

    .modal-title {
      font-size: 20px;
    }
  }
}
